AOS.init();

function AddMinutesToDate(date, minutes) {
    if(typeof date === 'string' || date instanceof String) {
        date = new Date(Number(date));
    }

    return Date.parse(date) + minutes * 60000;
}

function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';expires=' + expires.toUTCString();
}

function getCookie(key) {
    let keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
}

const phone_number = "#phone_number";
let input = document.querySelector(phone_number);
var iti = window.intlTelInput(input, {
    onlyCountries: ["ru", "by", "kz", "am", "kg", "az", "tj", "uz"],
    showFlags: true,
    autoPlaceholder: "aggressive",
    initialCountry: "auto",
    geoIpLookup: callback => {
        fetch("https://ipapi.co/json")
            .then(res => res.json())
            .then(data => callback(data.country_code))
            .catch(() => callback("ru"));
    },
    utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js"
});

$(function() {
    if(!getCookie("timer")) {
        setCookie("timer", Date.parse(new Date()), { expires : 7 });
    }

    //console.log(getCookie("timer"));


    $('.gift.gift_1').timeTo({
        timeTo: AddMinutesToDate(getCookie("timer"), 2),
        //seconds: 300,
        theme: "black"
    }, function(){
        console.log("Время вышло на получения подарка!");
    });

    $('.gift.gift_2').timeTo({
        //seconds: 120,
        timeTo: AddMinutesToDate(getCookie("timer"), 5),
        theme: "black"
    }, function(){
        console.log("Время получения подарка вышло!");
    });

    $('a[href^="#"]').click(function() {
        let href = $.attr(this, 'href');
        $('html, body').animate({
            scrollTop: $(href).offset().top
        }, 500, function() {
            window.location.hash = href;
        });
        return false;
    });

    if ($.validator) {
        $.validator.addMethod("require_from_group", function(value, element, options) {
            var validator = this;
            var selector = options[1];
            var validOrNot = $(selector, element.form).filter(function() {
                return validator.elementValue(this);
            }).length >= options[0];

            if(!$(element).data('being_validated')) {
                var fields = $(selector, element.form);
                fields.data('being_validated', true);
                fields.valid();
                fields.data('being_validated', false);
            }
            return validOrNot;
        });

        $.validator.addMethod(
            'regexp',
            function (value, element, regexp) {
                let re = new RegExp(regexp);
                return this.optional(element) || re.test(value);
            }
        );

        var validator = $("form").validate({
            rules: {
                firstname: {
                    required: true,
                    minlength: 3,
                    maxlength: 64,
                },
                phone_number: {
                    require_from_group: [1, ".phone-group"],
                    minlength: 7,
                    maxlength: 18,
                    regexp: "^[0-9\-\\s\)\(]+$",
                },
                skype: {
                    require_from_group: [1, ".phone-group"],
                    minlength: 3,
                    maxlength: 255,
                    regexp: "^(?!\\d)(?:(?![@#])[\\w])+$",
                },
                telegram: {
                    require_from_group: [1, ".phone-group"],
                    minlength: 3,
                    maxlength: 255,
                    regexp: "(?:@|(?:(?:(?:https?://)?t(?:elegram)?)\\.me\\/))(\\w{4,})$"
                    //regexp: "^(?!\\d)(?:(?![@#])[\\w])+$",
                },
            },

            messages: {
                firstname: "",
                phone_number: "",
                skype: "",
                telegram: "",
            },

            validClass: "is-valid",
            errorClass: "is-invalid",

            errorPlacement: function (error, element) {
                //just nothing, empty
            },

            submitHandler: function (form) {
                $.ajax({
                    url: form.action,
                    type: form.method,
                    data: $(form).serialize(),
                    success: function (json) {
                        let response = JSON.parse(json);

                        if(response.success) {
                            let success = new bootstrap.Modal($('#response_success'), {
                                keyboard: false
                            });

                            success.show();
                        }
                    },
                    error: function (json) {
                        let response = JSON.parse(json);
                        let message;

                        if(response.error) {
                            let error = new bootstrap.Modal($('#response_error'), {
                                keyboard: false
                            });

                            error.show();
                        }
                    }
                });

                $(form).trigger("reset");
                $(form).find(".is-valid").removeClass("is-valid");
                $(form).find(".is-invalid").removeClass("is-invalid");

                setTimeout(function () {
                    $('.form-response').find('.notyf__toast').addClass('notyf__toast--disappear');
                }, 3000);

                setTimeout(function () {
                    $('.form-response').html('');
                }, 4000);
            }
        });

        $(phone_number).on("countrychange", function(event) {

            // Get the selected country data to know which country is selected.
            let selectedCountryData = iti.getSelectedCountryData();

            // Get an example number for the selected country to use as placeholder.
            let newPlaceholder = intlTelInputUtils.getExampleNumber(selectedCountryData.iso2, true, intlTelInputUtils.numberFormat.INTERNATIONAL);

            // Reset the phone number input.
            iti.setNumber("");

            // Convert placeholder as exploitable mask by replacing all 1-9 numbers with 0s
            let mask = newPlaceholder.replace(/[1-9]/g, 0);

            validator.resetForm();

            // use the format as placeholder to jQuery-Mask input
            $(this).attr('placeholder', newPlaceholder);

            console.log(mask);

            //$(this).unmask();

            // Apply the new mask for the input
            $(this).mask(mask);
        });

        iti.promise.then(function() {
            $(phone_number).trigger("countrychange");
        });
    }
});

let video = document.querySelector('video');
video.muted = true;

video.addEventListener('loadeddata', (e) => {
    //Video should now be loaded but we can add a second check

    if(video.readyState >= 3){
        let promise = video.play();

        if (promise !== undefined) {
            promise.then(_ => {
                // Autoplay started!
                //video.muted = false;
            }).catch(error => {
                // Autoplay not allowed!
                // Mute video and try to play again
                video.controls = false;
                video.muted = true;
                video.play();

                // Show something in the UI that the video is muted
            });
        }
    }

});

function onSound() {
    console.log('click');

    video.play();
    video.muted = false;

    document.querySelector('#btn-sound').style.display = 'none';
}

